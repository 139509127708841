







import Vue, { PropType } from "vue";
import { callableFunctions } from "@/firebase";

import InvitationCodeForm from "./InvitationCodeForm.vue";
import Modal from "./Modal.vue";

export default Vue.extend({
  name: "InvitationCodeCreateModal",
  components: {
    InvitationCodeForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true }
  },
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    }
  },
  methods: {
    async createInvitationCode({
      code,
      permissionLevel,
      remainingUses
    }: {
      code: string;
      permissionLevel: AccountPermissions.Level;
      remainingUses: number;
    }): Promise<void> {
      await callableFunctions.createNewInvitationCode({
        code,
        permissionLevel,
        remainingUses,
        accountId: this.accountId
      });
      this.closeModal();
    }
  }
});
