







import Vue from "vue";

import QRCode from "qrcode";

export default Vue.extend({
  name: "QRCode",
  props: {
    code: { type: String, required: true }
  },
  data: () => ({
    dataURL: "",
    svgString: ""
  }),
  computed: {
    colorScheme(): string {
      return this.$store.direct.state.ui.preferredColorScheme;
    }
  },
  watch: {
    colorScheme(): void {
      this.updateDataUrl();
    }
  },
  mounted(): void {
    this.updateDataUrl();
  },
  methods: {
    updateDataUrl(): void {
      void QRCode.toDataURL(
        this.code,
        {
          scale: 20,
          errorCorrectionLevel: "H",
          color: {
            dark: this.colorScheme == "dark" ? "#F2F2F2FF" : "#000000FF",
            light: this.colorScheme == "dark" ? "#212122FF" : "#FFFFFFFF"
          }
        },
        (error, url) => {
          if (error) {
            console.error(error);
          } else {
            this.dataURL = url;
          }
        }
      );
      // QRCode.toString(this.code, { type: "svg" }, (err, svgString) => {
      //   this.dataURL = `data:image/svg+xml;utf8,${base64.encode(svgString)}`;
      // });
    }
  }
});
