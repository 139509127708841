





















































import Vue, { PropType } from "vue";
import isInteger from "lodash/isInteger";

import ActionButton from "@/components/ActionButton.vue";
import NumberInput from "@/components/NumberInput.vue";
import Radio from "@/components/Radio.vue";
import GetInvitationCodeLink from "@/components/GetInvitationCodeLink.vue";
import * as permissionLevels from "@/permissions";

interface InvitationCodeFormValues {
  code?: string;
  permissionLevel?: AccountPermissions.Level;
  remainingUses?: number;
}

export default Vue.extend({
  name: "InvitationCodeForm",
  components: {
    GetInvitationCodeLink,
    Radio,
    NumberInput,
    ActionButton
  },
  props: {
    type: {
      type: String,
      required: true
    },
    formValues: {
      type: Object as PropType<InvitationCodeFormValues>,
      default: () => ({})
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    code: "",
    permissionLevel: "normal" as AccountPermissions.Level,
    remainingUses: 5,
    submitting: false,
    error: null as Error | null
  }),
  computed: {
    permissionLevels() {
      return permissionLevels;
    },
    editing() {
      return this.type === "Save";
    }
  },
  watch: {
    formValues: {
      immediate: true,
      handler({ code, permissionLevel, remainingUses }: InvitationCodeFormValues) {
        this.code = code || "";
        this.permissionLevel = permissionLevel || "normal";
        this.remainingUses = remainingUses && isInteger(remainingUses) ? remainingUses : 5;
      }
    }
  },
  methods: {
    onInput(): void {
      this.code = this.code.replace(/\s+/g, "").replace(/\/+/g, "") ?? "";
    },
    async createInvitationCode(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          if (!this.code) {
            throw new Error("You must enter an invitation code");
          }

          await this.onSubmit({
            code: this.code,
            permissionLevel: this.permissionLevel,
            remainingUses: this.remainingUses
          });
        } catch (error) {
          console.error(error);
          this.error = error as Error;
        }
        this.submitting = false;
      }
    }
  }
});
