




























import Vue from "vue";
import InvitationCodeEditModal from "./InvitationCodeEditModal.vue";
import UserName from "./UserName.vue";

export default Vue.extend({
  name: "InvitationCodeListItem",
  components: {
    InvitationCodeEditModal,
    UserName
  },
  props: {
    invitationCode: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editModalIsOpen: false
    };
  },
  methods: {
    openEditModal() {
      this.editModalIsOpen = true;
    },
    closeEditModal() {
      this.editModalIsOpen = false;
    }
  }
});
