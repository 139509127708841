
























import Vue, { PropType } from "vue";

import ActionButton from "./ActionButton.vue";
import UserName from "./UserName.vue";
import Modal from "./Modal.vue";
import InvitationCodeForm from "./InvitationCodeForm.vue";

import { db, callableFunctions } from "../firebase";

interface InvitationCodeWithId extends InvitationCode {
  id: string;
}

interface InvitationCodeUsedBy {
  id: string;
  timestamp: Timestamp;
}

export default Vue.extend({
  name: "InvitationCodeEditModal",
  components: {
    ActionButton,
    InvitationCodeForm,
    Modal,
    UserName
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function, required: true },
    invitationCode: { type: Object as PropType<InvitationCodeWithId>, required: true }
  },
  data() {
    return {
      deletingInvitationCode: false,
      error: null as Error | null,
      usedBy: [] as Array<InvitationCodeUsedBy>
    };
  },
  computed: {
    formValues() {
      return {
        code: this.invitationCode.id,
        permissionLevel: this.invitationCode.permissionLevel,
        remainingUses: this.invitationCode.remainingUses,
        account: this.invitationCode.account
      };
    }
  },
  watch: {
    open() {
      if (this.open) {
        db.collection("invitationCodes")
          .doc(this.invitationCode.id)
          .collection("usedBy")
          .get()
          .then(usedBySnapshot => {
            const newUsedByList: Array<InvitationCodeUsedBy> = [];
            usedBySnapshot.forEach(user => {
              newUsedByList.push({ id: user.id, ...user.data() } as InvitationCodeUsedBy);
            });
            this.usedBy = newUsedByList;
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  },
  methods: {
    async editInvitationCode({
      code,
      permissionLevel,
      remainingUses
    }: {
      code: string;
      permissionLevel: string;
      remainingUses: number;
    }) {
      await callableFunctions.editInvitationCode({
        code,
        permissionLevel,
        remainingUses,
        accountId: this.invitationCode.account
      });
      this.closeModal();
    },
    async deleteInvitaitonCode() {
      if (this.deletingInvitationCode === false) {
        this.deletingInvitationCode = true;
        try {
          await db.collection("invitationCodes").doc(this.invitationCode.id).delete();
        } catch (error) {
          console.error(error);
          this.error = error as Error;
        }
        this.deletingInvitationCode = false;
      }
    }
  }
});
