























import Vue from "vue";

import ActionButton from "./ActionButton.vue";
import ErrorNotice from "./ErrorNotice.vue";
import LinkIcon from "../icons/Link.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import QRCode from "./QRCode.vue";

const apiKey = "AIzaSyAEPflagoag4qIcTGZs0enakaaG4F2a264";

interface shortLinkResponse {
  shortLink: string;
}

export default Vue.extend({
  name: "GetInvitationCodeLink",
  components: {
    ActionButton,
    ErrorNotice,
    LinkIcon,
    LoadingIndicator,
    QRCode
  },
  props: {
    code: { type: String, required: true }
  },
  data: () => ({
    sharing: false,
    shareSuccess: false,
    url: "",
    error: null as Error | null
  }),
  computed: {
    isLoading(): boolean {
      return this.url === null && this.error === null;
    }
  },
  mounted() {
    this.invitationCodeLink()
      .then(url => (this.url = url))
      .catch((error: unknown) => {
        console.error(error);
        if (error instanceof Error) {
          this.error = error;
        } else {
          this.error = new Error(JSON.stringify(error));
        }
      });
  },
  methods: {
    async createShortLink(longDynamicLink: string): Promise<string> {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            longDynamicLink,
            suffix: {
              option: "UNGUESSABLE"
            }
          })
        }
      );
      const body = (await response.json()) as shortLinkResponse;
      console.log("createShortLinkResponse", body);
      return body.shortLink;
    },
    async invitationCodeLink(): Promise<string> {
      console.log("invitationCodeLink", this.code);
      const bundleId =
        window.APP_INFO?.build?.basePackageName ?? "com.inventoryshield.referralreactor";
      const appStoreId = "1508626131";
      const rawParams = { invitationCode: this.code };
      const params = new URLSearchParams(rawParams).toString();
      const encodedLink = encodeURIComponent(`https://app.referralreactor.com/signup?${params}`);
      // const encodedLink = encodeURIComponent(`http://localhost:8080/signup?${params}`);

      // https://firebase.google.com/docs/dynamic-links/create-manually
      const longUrl = `https://referralreactor.page.link/?link=${encodedLink}&apn=${bundleId}&ibi=${bundleId}&isi=${appStoreId}`;
      const url = await this.createShortLink(longUrl);
      console.log("invitationCodeLink", url);

      return url;
    },
    async getAndShareInvitationCodeLink(): Promise<void> {
      if (!this.url) {
        return;
      }
      console.log("getAndShareInvitationCodeLink", this.url);
      if (this.sharing !== true) {
        this.sharing = true;
        this.shareSuccess = false;
        this.error = null;
        try {
          await this.$store.direct.dispatch.copyAndShareURL(this.url);
          this.shareSuccess = true;
        } catch (error) {
          console.error("Code share failed:", error);
          if (error instanceof Error) {
            this.error = error;
          }
        } finally {
          this.sharing = false;
        }
      }
    }
  }
});
