



















import Vue from "vue";
import orderBy from "lodash/orderBy";
import ListFirestore from "@/components/ListFirestore.vue";
import InvitationCodeCreateModal from "@/components/InvitationCodeCreateModal.vue";
import InvitationCodeListItem from "@/components/InvitationCodeListItem.vue";
import ActionButton from "@/components/ActionButton.vue";
import { db } from "@/firebase";

export default Vue.extend({
  name: "AccountInvitationCodes",
  components: {
    ActionButton,
    InvitationCodeCreateModal,
    InvitationCodeListItem,
    ListFirestore
  },
  data: () => ({
    createModalOpen: false
  }),
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    }
  },
  methods: {
    closeCreateModal() {
      this.createModalOpen = false;
    },
    getInvitationCodeCollection() {
      return db
        .collection("invitationCodes")
        .where("account", "==", this.accountId)
        .orderBy("createdAt", "desc");
    },
    orderList(items: InvitationCode[]) {
      return orderBy(Object.values(items), "createdAt", "desc");
    }
  }
});
